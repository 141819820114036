<template>
  <a-spin :spinning="spinning" class="spinning_box">
    <div class="dataScreen_content_box_left">
      <div class="dataScreen_title">
        <TitlesImg :type="record.titleImgType" />
        <div class="titlePosition" style="margin-left: 2em;">
          {{ record.title }}
        </div>
      </div>
      <div class="dataScreen_content_left_top_box1_top">
        <img src="../../assets/img/imgStaly1/podium_bg.png" class="dataScreen_content_left_top_box1_top_img" alt="">
        <span class="dataScreen_content_left_top_box1_top_span1">{{deyuTopThree[0].name}}
        </span>
        <span class="dataScreen_content_left_top_box1_top_span2">{{deyuTopThree[1].name}}</span>
        <span class="dataScreen_content_left_top_box1_top_span3">{{deyuTopThree[2].name}}</span>
        <div class="dataScreen_content_left_top_box1_top_box">
          <span class="dataScreen_content_left_top_box1_top_box_span">
            <span class="dataScreen_content_left_top_box1_top_box_span1">排名</span>
            <span class="dataScreen_content_left_top_box1_top_box_span2">班级</span>
          </span>
          <span class="dataScreen_content_left_top_box1_top_box_span2">积分</span>
        </div>
      </div>
      <div class="dataScreen_content_left_top_box1_bottom">
        <vue-seamless-scroll v-if="deyuList.length>=1" :data="deyuList" class="seamless-warp"
          :class-option="defaultOption">
          <ul class="ul-scoll">
            <li v-for="(item, index) in deyuList" class="ul_scoll_li" :key="index">
              <div class="item dataScreen_content_left_top_box1_bottom_container_box">
                <span class="dataScreen_content_left_top_box1_bottom_container_box_left">
                  <span class="dataScreen_content_left_top_box1_bottom_container_box_left_span">{{item.Ranking}}
                  </span>
                  <span>{{item.name}}</span>
                </span>
                <span class="dataScreen_content_left_top_box1_bottom_container_box_span">{{item.score}}</span>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </a-spin>
</template>

<script>
  import TitlesImg from '@/components/CustomModule/TitlesImg.vue'
  import {
    bigscreenLeftV2
  } from '@/api/other'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "DyComprehensiveAppraisal",
    props:{
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      },
    },
    components: {
      vueSeamlessScroll,
      TitlesImg
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
        }
      }
    },
    data() {
      return {
        spinning: false,
        deyuTopThree: [{
            id: 1,
            name: "-",
          },
          {
            id: 2,
            name: "-",
          },
          {
            id: 3,
            name: "-",
          },
        ],
        deyuList: []
      };
    },
    methods: {
      loadData() {
        this.spinning = true
        bigscreenLeftV2({
            subSchoolId: this.currentSubSchoolId,
            gradeIds: this.record.gradeIds.join(','),
            sType: 0,
            useFakeData: this.record.useFakeData?this.record.useFakeData:0
          },
          (resp) => {
            const resData = resp.data
            this.deyuList = resData
            this.deyuTopThree = []
            this.deyuTopThree.push(resData[1]?resData[1]:{id: 2, name: "-"})
            this.deyuTopThree.push(resData[0]?resData[0]:{id: 1, name: "-"})
            this.deyuTopThree.push(resData[2]?resData[2]:{id: 3, name: "-"})
            let Ranking = 1
            for (let i = 0; i < this.deyuList.length; i++) {
              if (i == 0) {
                this.deyuList[i].Ranking = Ranking
              } else {
                Ranking += 1
                if (this.deyuList[i].score == this.deyuList[i-1].score) {
                  this.deyuList[i].Ranking = this.deyuList[i-1].Ranking
                } else {
                  this.deyuList[i].Ranking = Ranking
                }
              }
            }
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      }

    },
    mounted() {
      this.loadData()
    }
  };

</script>

<style scoped>
.ul_scoll_li {
  color: #fff;
  padding: 5px 20px;
}

.ul_scoll_li:nth-child(2n) {
  background-color: #07369E;
}
</style>
