  <template>
    <div class="imgBox">
      <img v-if="type == 1" class="title1_img" src="../../assets/img/imgStaly1/deyu_title_bg01.png" />
      <img v-if="type == 2" class="title1_img" src="../../assets/img/imgStaly1/title_bg_weisheng_420.png" />
      <img v-if="type == 3" class="title1_img" src="../../assets/img/imgStaly1/title_bg_deyu_860.png" />
      <img v-if="type == 4" class="title1_img" src="../../assets/img/imgStaly1/title_bg_zizhuxuexi_1840.png" />
      <img v-if="type == 5" class="title1_img" src="../../assets/img/imgStaly1/jiaoxue_title_bg.png" />
      <img v-if="type == 6" class="title1_img" src="../../assets/img/imgStaly1/deyu_bigtitle_bg.png" />
      <img v-if="type == 7" class="title1_img" src="../../assets/img/imgStaly1/deyu_mediumtitle_bg.png" />
      <img v-if="type == 8" class="title1_img" src="../../assets/img/imgStaly1/deyu_littletitle_bg.png" />
      <img v-if="type == 9" class="title1_img" src="../../assets/img/imgStaly1/title_long_bg.png" />
      <img v-if="type == 10" class="title1_img" src="../../assets/img/imgStaly1/title_short_bg.png" />
      <img v-if="type == 11" class="title1_img" src="../../assets/img/imgStaly1/title_long_bg02.png" />
      <img v-if="type == 12" class="title1_img" src="../../assets/img/imgStaly1/title_short_bg02.png" />
      <img v-if="type == 13" class="title1_img" src="../../assets/img/imgStaly1/littletitle_bg02.png" />
      <img v-if="type == 14" class="title1_img" src="../../assets/img/imgStaly1/beike_littletitle_bg.png" />
      <img v-if="type == 15" class="title1_img" src="../../assets/img/imgStaly1/homework_littletitle_bg.png" />
      <img v-if="type == 16" class="title1_img" src="../../assets/img/imgStaly1/keshi_littletitle_bg.png" />
      <img v-if="type == 17" class="title1_img" src="../../assets/img/imgStaly1/shiti_littletitle_bg.png" />
    </div>
  </template>
  
  <script>
    export default {
      name: "TitlesImg",
      props:{
        type: {
          type: String
        }
      },
      watch: {
      },
      data() {
        return {
        };
      },
      methods: {
      },
      mounted() {
      }
    };
  
  </script>
  
  <style scoped>
    .imgBox {
      display: flex;
      justify-content: left;
      width: 100%;
      height: 100%;
    }
    .title1_img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  </style>
  